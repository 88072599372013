import React, { useState } from "react";
import ReactDOM from "react-dom";

import { Router, Link } from "@reach/router"
import Video from "./Video"

import Drift from "react-driftjs";

const App = () => {
    return (
        <div>
            <Router>
                <Video default videoId="358256574" />
            </Router>
            <Drift appId="ggzkszwvxwcw" />
        </div>
    );
};

ReactDOM.render(<App />, document.getElementById("app"));