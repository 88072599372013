import React, { useState } from "react";
import ReactDOM from "react-dom";

import Plyr from "react-plyr";

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        isVideoPlaying: false
    };

    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(state => ({
      isVideoPlaying: !state.isVideoPlaying
    }));
  }

  render() {
	this.controls = this.props.controls || ["play-large", "fullscreen"];
	return (
		<div className={"container " + (this.state.isVideoPlaying ? "blackout" : "")}>
			<div className="video-container">
				<Plyr
					type="vimeo"
					controls={this.controls}
					videoId={this.props.videoId}
					onPlay={this.handleClick}
					onPause={this.handleClick}
				/>
			</div>
		</div>
	);
	}
}

export default Video;